import * as React from "react"
import Image from 'gatsby-image'

const Connect = ({props}) => {

  const { connectTitle, connectCopy, connectImage, connectLinks } = props

  return (
    <div className="bg-oceanic-blue">
      <div className="text-white pt-18 lg:pt-32 pb-16">
        <div className="max-w-page text-center">
          <h2 className="headline-2 mb-4 md:mb-5">{connectTitle}</h2>
          <p className="body-2 w-full md:w-3/5 mx-auto mb-8 text-base med:text-lg lg:text-lg">{connectCopy}</p>
          <div className="mb-12 md:mb-20 w-40 md:w-full mx-auto" style={{ maxWidth: '240px' }}>
            <Image
              fluid={connectImage.fluid}
              imgStyle={{ objectFit: 'contain', width: '100%', height: 'auto' }}
              loading='eager'
              alt='N/A'
              aria-hidden
            />
          </div>
          <div className="flex flex-col lg:flex-row flex-nowrap lg:gap-16 text-left">
            <div className="col w-full lg:w-1/2">
              {
                connectLinks.resources.slice(0,4).map((item, index) => (
                  <div key={index} className="border-b">
                    <a className="flex justify-between items-center py-4" href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className="w-5/6 md:w-full">{item.name}</span>
                      <img src="/images/purple-arrow.png" />
                    </a>

                  </div>
                ))
              }
            </div>
            <div className="col w-full lg:w-1/2">
            {
                connectLinks.resources.slice(4).map((item, index) => (
                  <div key={index} className="border-b">
                    <a className="flex justify-between items-center py-4" href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className="w-5/6 md:w-full">{item.name}</span>
                      <img src="/images/purple-arrow.png" className="w-7 h-7" />
                    </a>

                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      {/*<div className="connect-wave-bottom"></div>*/}
    </div>
  )
}

export default Connect
