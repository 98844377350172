import * as React from "react"
import Image from 'gatsby-image'

const MoreResources = ({props}) => {

  const { moreResourcesTitle, moreResourcesCopy, moreResourcesImage, moreResourcesLinks, column1TitleBlue, column2TitleBlue } = props

  return (
    <div>
      {/*<div className="purple-wave-bottom"></div>*/}
        <div className="max-w-page py-10 md:py-16 text-center">
        <h2 className="headline-2 text-oceanic-blue mb-2 md:mb-4">{moreResourcesTitle}</h2>
        <p className="body-3 md:w-4/5 lg:w-1/2 mx-auto mb-8 md:mb-10">{moreResourcesCopy}</p>
          <div className="mb-12 md:mb-20 w-40 md:w-full mx-auto" style={{ maxWidth: '240px' }}>
          <Image
            fluid={moreResourcesImage.fluid}
            imgStyle={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            loading='eager'
            alt='N/A'
            aria-hidden
          />
        </div>
        <div className="flex flex-col lg:flex-row flex-nowrap lg:gap-16 text-left">
          <div className="col w-full lg:w-3/5">
            <br />
            <br />
            <h3 className="font-extrabold text-xl leading-tight mb-4 text-oceanic-blue">{column1TitleBlue}</h3>
            {
              moreResourcesLinks.resources.slice(0,2).map((item, index) => (
                <div key={index} className="border-b">
                  <a className="flex py-4 column-content flex-col items-start md:flex-row md:justify-between md:items-center" href={item.url} target="_blank" rel="noopener noreferrer">
                    <span className="block mb-2 med:hidden lg:hidden">{item.title}</span>
                    <img src={item.image}  className="mb-2" />
                    <span className="column-text">
                     <span className="hidden mt-2 mb-1 med:block lg:block">{item.title}</span>
                     <span><b>{item.name}</b></span><br />
                     <p className="my-2">{item.text}</p>
                   </span>
                    <img src="/images/purple-arrow.png" />
                  </a>
                </div>
              ))
            }
          </div>
          <div className="col w-full lg:w-2/5">
            <br />
            <br />
            <h3 className="font-extrabold text-xl leading-tight mb-4 text-oceanic-blue">{column2TitleBlue}</h3>
            {
              moreResourcesLinks.resources.slice(2).map((item, index) => (
                <div key={index} className="border-b">
                  <a className="flex justify-between items-center py-4" href={item.url} target="_blank" rel="noopener noreferrer">
                    <span className="w-5/6 md:w-full">{item.name}</span>
                    <img src="/images/purple-arrow.png" className="w-7 h-7" />
                  </a>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoreResources
