import * as React from "react"
import Image from 'gatsby-image'

const Hero = ({props}) => {

  const { heroTitleLightBlue, heroTitleBlue, heroCopy, heroImage } = props

  return (
    <div className="max-w-page pt-18 flex flex-col lg:flex-row">
      <div className="flex-1">
        <div>
          <h1 className='headline-1 mb-4 lg:mb-6 max-w-2xl'>
            <span className="text-light-blue">{heroTitleLightBlue}</span>
            <span className="text-oceanic-blue"> {heroTitleBlue}</span>
          </h1>
          <p className="body-2 mb-10 lg:mb-0">
            {heroCopy.heroCopy}
          </p>
        </div>
      </div>
      <div className="flex-1 relative">
        <Image
              fluid={heroImage.fluid}
              imgStyle={{objectFit: 'contain', objectPosition: 'center', height: 'auto',  }}
              className='w-full md:w-3/5 lg:w-full mx-auto'
              loading='eager'
              alt='Happy dad holding his son'
              aria-hidden
        />
      </div>
    </div>
  )
}

export default Hero