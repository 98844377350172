import * as React from "react"
import Image from 'gatsby-image'

const ShareYourOrganization = ({props}) => {

  const { organizationFormTitle, organizationFormBody, organizationFormFields, organizationFormImage, organizationFormSeperator, organizationFormSubmissionSuccess, organizationFormValueRequired } = props

  const [formValues, setFormValues] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setSubmitting(true);
      setSubmissionSuccess(false);
      fetch('https://42vsmxsfa4.execute-api.us-east-1.amazonaws.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      })
        .then((response) => response.json())
        .then((data) => {
          setSubmitting(false);
          setSubmissionSuccess(true);
          // reset form states
          setFormValues({});
          setErrors({});
        })
        .catch((error) => {
          setSubmitting(false);
          setSubmissionSuccess(false);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update form values
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Validate the individual field and clear the error if valid
    const error = validateField(name, value);
    setErrors((prevErrors) => {
      if (error) {
        return { ...prevErrors, [name]: error };
      } else {
        // Remove the error for the field if valid
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      }
    });
  };

  const validateField = (name, value) => {
    let error = '';

    const field = organizationFormFields.formFields.find(f => f.name === name);
    if ((field.type === 'input' || field.type === 'textarea') && !value) {
      error = `${field.label.replace(/:/g, '')} is required`;
    }

    return error;
  };
  const validateForm = () => {
    const newErrors = {};

    organizationFormFields.formFields.forEach((field) => {
      const value = formValues[field.name] || '';
      const error = validateField(field.name, value);
      if (error) {
        newErrors[field.name] = error;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  return (
    <div className="bg-magestic-purple">
      <div className="professionals-wave-top bg-transparent"></div>
      <div className="py-9 lg:py-18 ">
        <div className="max-w-page flex flex-col-reverse lg:flex-row gap-12 md:gap-30">
          <div className="h-full w-full md:w-50 justify-center">
            <Image
              fluid={organizationFormImage.fluid}
              imgStyle={{objectFit: 'contain', objectPosition: 'center', width: '100%', height: 'auto', paddingBottom: 0 }}
              loading='eager'
              alt='N/A'
              aria-hidden
            />
          </div>
          <div className="md:w-50 flex flex-col gap-6">
            <h2 className="headline-3 text-white">{organizationFormTitle}</h2>
            <div className="md:mb-6" style={{ maxWidth: '240px' }}>
              <img src={organizationFormSeperator.file.url} />
            </div>
            <p className="body-2 w-full text-white">{organizationFormBody}</p>
            <form className="relative flex flex-col gap-6">
            {organizationFormFields.formFields.map((field, index) => {
              if (field.type === 'input') {
                return (
                  <div className="relative" key={index}>
                    <label className="block text-white text-sm font-semibold mb-2" htmlFor={field.name}>
                      {field.label}
                    </label>
                    <input
                      id={field.name}
                      name={field.name}
                      value={formValues[field.name] || ''}
                      onChange={handleInputChange}
                      disabled={submitting}
                      type="text"
                      placeholder={field.placeholder}
                      className={`w-full p-3 ${errors[field.name] ? 'border-pink-salmon border-2' : 'border border-gray-300'} rounded-lg text-black placeholder-gray-muted`}
                    />
                    {errors[field.name] && <p className="absolute py-1 text-pink-salmon font-bold text-xs">{organizationFormValueRequired}</p>}
                  </div>
                );
              } else if (field.type === 'textarea') {
                return (
                  <div className="relative" key={index}>
                    <label className="block text-white text-sm font-semibold mb-2" htmlFor={field.name}>
                      {field.label}
                    </label>
                    <textarea
                      id={field.name}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formValues[field.name] || ''}
                      onChange={handleInputChange}
                      disabled={submitting}
                      className={`w-full p-3 ${errors[field.name] ? 'border-pink-salmon border-2' : 'border border-gray-300'} rounded-lg text-black placeholder-gray-muted`}
                      rows="4"
                    />
                    {errors[field.name] && <p className="absolute text-pink-salmon font-bold text-xs">{organizationFormValueRequired}</p>}
                  </div>
                );
              } else if (field.type === 'submit') {
                return (
                  <div className="mt-6" key={index}>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={submitting}
                      className="bg-white text-oceanic-blue font-extrabold px-6 py-3 rounded-lg shadow hover:bg-oceanic-blue hover:text-white border-2 border-oceanic-blue hover:text-white transition duration-300 ease-in-out"
                    >
                      {field.value} <em className='ml-2 fas fa-caret-right ml-2' />
                    </button>
                  </div>
                );
              } else {
                return null;
              }
            })}
            {submissionSuccess && <p className="absolute bottom-18 text-white font-bold text-sm">{organizationFormSubmissionSuccess}</p>}
            </form>
          </div>
        </div>
      </div>
      <div className="professionals-wave-bottom"></div>
    </div>
  )
}

export default ShareYourOrganization
