import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Hero from '~components/professionals/hero'
import Connect from '~components/professionals/connect'
import ShareYourOrganization from '~components/professionals/share-your-organization'
import MoreResources from '~components/professionals/more-resources'

const Professionals = (props) => {

  const { section, locale } = props.pageContext
  const { location } = props
  const { settings } = props.data

  const professionals = props.data.contentfulProfessionals

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title={professionals?.metaTitle || professionals.title} description={professionals?.metaDescription} />
      <Hero props={professionals} />
      <Connect props={professionals} />
      <ShareYourOrganization props={professionals} />
      <MoreResources props={professionals} />
    </Layout>
  )
}

export const professionalsQuery = graphql`
  query professionalsQuery($locale: String!) {
    contentfulProfessionals(node_locale: { eq: $locale }) {
      title
      metaTitle
      metaDescription
      heroTitleLightBlue
      heroTitleBlue
      heroCopy {
        heroCopy
      }
      heroImage {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      connectTitle
      connectCopy
      connectImage {
        fluid(maxWidth: 240, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      connectLinks {
        resources {
          name
          url
        }
      }
      organizationFormTitle
      organizationFormBody
      organizationFormFields {
        formFields {
          label
          placeholder
          name
          type
          value
        }
      }
      organizationFormImage {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      organizationFormSeperator {
        file {
          url
        }
      }
      organizationFormSubmissionSuccess
      organizationFormValueRequired
      moreResourcesTitle
      moreResourcesCopy
      moreResourcesImage {
        fluid(maxWidth: 240, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      moreResourcesLinks {
        resources {
          name
          url
          title
          text
          image
        }
      }
      column1TitleBlue
      column2TitleBlue
    }
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      californiaStateSiteLink
    }
  }
`
export default Professionals
